import { Constants } from '../Constants';

const NAV_LINKS = [
    {
        title: 'Home',
        route: '/',
    },
    {
        title: 'Guides',
        route: Constants.PATHS.GUIDES,
    },
    {
        title: 'Developers',
        route: Constants.PATHS.DEVELOPERS,
    },
    {
        title: 'Integrations',
        route: Constants.PATHS.INTEGRATIONS,
    },
    {
        title: 'Changelog',
        route: Constants.PATHS.CHANGELOG,
    },
]

const SIGNUP_LINK =  {
    title: 'Sign Up',
    route: 'https://www.mparticle.com/free-trial'
};

export { NAV_LINKS, SIGNUP_LINK };