import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveContainer from '../../../ResponsiveContainer/responsiveContainer';
import LinkText from '../../../../components/atoms/LinkText/linkText';
import { Theme } from '../../../../constants/Constants';
import './_footerLegal.less';

const FooterLegal = ({ links, currentYear, onShowOneTrustBanner }) => {
    if (!links) return null;

    return (
        <div className='footer-legal'>
            <ResponsiveContainer>
                <nav className='footer-legal__nav'>
                    <small
                        className='footer-legal__text'
                        data-cy='footer-legal__text'>
                        {`© ${currentYear} mParticle, Inc. All rights reserved.`}
                    </small>
                    {links.map((link) => (
                        <LinkText
                            key={`${link.title}${link.route}`}
                            isExternal
                            route={link.route}
                            text={link.title}
                            theme={Theme.DARK}
                            linkModifier='footer-legal'
                            dataCy='footer-legal'
                        />
                    ))}
                    <>
                        {/* suggested by One Trust support to fix button stop working when navigating between pages */}
                        <a
                            className='footer-legal__nav ot-sdk-show-settings'
                            id='ot-sdk-btn'
                            style={{ display: 'none' }}
                        />
                        <LinkText
                            isExternal
                            text='Do Not Sell or Share My Personal Data'
                            route='#'
                            theme={Theme.DARK}
                            linkModifier='footer-legal'
                            onLinkClick={onShowOneTrustBanner}
                            dataCy='footer-ot'
                        />
                    </>
                </nav>
            </ResponsiveContainer>
        </div>
    );
};

FooterLegal.propTypes = {
    links: PropTypes.array.isRequired,
    currentYear: PropTypes.number.isRequired,
    onShowOneTrustBanner: PropTypes.func,
};

FooterLegal.defaultProps = {
    onShowOneTrustBanner: () => {},
};

export default FooterLegal;
