import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Constants } from '../../constants/Constants';
import MparticleLogoSvg from '../../../static/images/2023-ui/icons/mParticle.svg';
import './_mParticleLogo.less';

const MParticleLogo = ({ size, logoModifier, dataCy }) => {
    const logoClassName = `mparticle-logo ${logoModifier ? `mparticle-logo--${logoModifier}` : ''}`;

    return (
        <Link
            to={Constants.PATHS.HOME}
            aria-label='mParticle Logo'
            className={logoClassName}
            data-cy={`mparticle-logo${dataCy ? `--${dataCy}` : ''}`}>
            <MparticleLogoSvg
                alt='mParticle Logo'
                className={`mparticle-logo__svg mparticle-logo__svg--${size}`}
            />
            <span className={`mparticle-logo__text mparticle-logo__text--${size}`}>
                DOCS
            </span>
        </Link>
    );
};

MParticleLogo.propTypes = {
    size: PropTypes.oneOf(['small', 'large']),
    logoModifier: PropTypes.string,
    dataCy: PropTypes.string,
};

MParticleLogo.defaultProps = {
    size: 'large',
    logoModifier: '',
    dataCy: '',
};

export default MParticleLogo;
