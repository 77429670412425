import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import './_scrolltopbutton.less';

const ScrollTopButton = () => (
    <button
        className='scroll-top-button'
        type='button'
        onClick={() => {
            scroll.scrollToTop();
        }}>
        <span className='scroll-top-button__icon' />
    </button>
);

export default ScrollTopButton;
