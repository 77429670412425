import React from 'react';
import AndroidIcon from '../../static/images/2023-ui/icons/Platform/Android.svg';
import IOSIcon from '../../static/images/2023-ui/icons/Platform/iOS.svg';
import WebIcon from '../../static/images/2023-ui/icons/Platform/Web.svg';
import ChevronRight from '../../static/images/2023-ui/icons/ChevronRight.svg';
import DocsIcon from '../../static/images/2023-ui/icons/Docs.svg';

const ICONS = {
    'AndroidIcon': <AndroidIcon />,
    'IOSIcon': <IOSIcon />,
    'WebIcon': <WebIcon />,
    'ChevronRight': <ChevronRight />,
    'DocsIcon': <DocsIcon />,
};

export { ICONS };