import React from 'react';
import PropTypes from 'prop-types';
import './_responsiveContainer.less';

const ResponsiveContainer = ({ children }) => (
    <div className='responsive-container'>
        {children}
    </div>
);

ResponsiveContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ResponsiveContainer;
