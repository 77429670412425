import React from 'react';
import PropTypes from 'prop-types';
import LinkText from '../../../../components/atoms/LinkText/linkText';
import { Theme } from '../../../../constants/Constants';
import './_headerNavigationLinks.less';

const HeaderNavigationLinks = ({ links, isNavLinkActive }) => {
    if (!links) return null;

    return (
        <ul className='header-navigation-links'>
            {links.map((link) => (
                <li key={`${link.route}${link.title}`}>
                    <LinkText
                        route={link.route}
                        text={link.title}
                        theme={Theme.DARK}
                        isActive={isNavLinkActive(link.route)}
                        linkModifier='header-navigation'
                        dataCy='header-navigation-links'
                    />
                </li>
            ))}
        </ul>
    );
};

HeaderNavigationLinks.propTypes = {
    links: PropTypes.array.isRequired,
    isNavLinkActive: PropTypes.func.isRequired,
};

export default HeaderNavigationLinks;
