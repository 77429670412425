import React, { useState, useEffect } from 'react';
import MParticleLogo from '../MParticleLogo/mParticleLogo';
import HeaderNavigationLinks from './components/HeaderNavigationLinks/headerNavigationLinks';
import MenuButton from './components/MenuButton/menuButton';
import LinkButton from '../../components/atoms/LinkButton/linkButton';
import Search from '../../components/Search/search';
import { routePropTypes } from '../../utils/routes';
import { toggleScrollDisabled } from '../../utils/misc';
import { Constants } from '../../constants/Constants';
import { NAV_LINKS, SIGNUP_LINK } from  '../../constants/layouts/APP_HEADER';
import './_appHeader.less';

const AppHeader = ({ location }) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const openSearch = (which) => setIsSearchOpen(which);

    const handleMobileMenuToggle = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    const setNavLinkActive = (pathname) => {
        if (pathname === Constants.PATHS.HOME) {
            return location.pathname === Constants.PATHS.HOME;
        }
        return location.pathname.indexOf(pathname) >= 0;
    };

    const getMobileMenuAwareClassName = (className) => isMobileMenuOpen ? `${className} ${className}--open` : `${className}`;

    useEffect(() => {
        // disabling scroll on html body if mobile menu is open (covers entire screen)
        toggleScrollDisabled(isMobileMenuOpen);
    }, [isMobileMenuOpen]);

    return (
        <>
            <header className={getMobileMenuAwareClassName('app-header')}>
                <div className='app-header__container'>
                    <div className='app-header__logo'>
                        <MParticleLogo size='large' dataCy='app-header' />
                    </div>

                    {renderMobileMenu()}

                    <nav
                        className={getMobileMenuAwareClassName('app-header__nav')}
                        data-cy='app-header__nav'>
                        <HeaderNavigationLinks
                            links={NAV_LINKS}
                            isNavLinkActive={setNavLinkActive}
                        />
                    </nav>

                    <div
                        className={getMobileMenuAwareClassName('app-header__actions')}
                        data-cy='app-header__actions'>
                        <Search
                            className='search'
                            openSearch={openSearch}
                            alwaysShowClose
                            collapsed={isSearchOpen}
                        />
                        <LinkButton
                            isExternal
                            title={SIGNUP_LINK.title}
                            route={SIGNUP_LINK.route}
                            buttonModifier={isMobileMenuOpen ? 'app-header-open' : ''}
                            dataCy='app-header'
                        />
                    </div>
                </div>
            </header>

            {renderMobileSearch()}
        </>
    );

    function renderMobileMenu() {
        return (
            <div
                className={getMobileMenuAwareClassName('app-header__mobile-menu')}
                data-cy='app-header__mobile-menu'>
                <MParticleLogo size='small' logoModifier={isMobileMenuOpen ? 'app-header-open' : ''} />
                <MenuButton
                    isOpen={isMobileMenuOpen}
                    handleBtnToggle={handleMobileMenuToggle}
                />
            </div>
        );
    }

    // todo: remove with search update
    function renderMobileSearch() {
        return (
            <div className='app-header__search-breadcrumb'>
                <Search
                    className='search breadcrumb-search'
                    openSearch={openSearch}
                    alwaysShowClose
                    collapsed={isSearchOpen}
                />
            </div>
        );
    }
};

AppHeader.propTypes = {
    location: routePropTypes.location.isRequired,
};

export default AppHeader;
