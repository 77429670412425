import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '../../../../../static/images/2023-ui/icons/Menu.svg';
import CloseIcon from '../../../../../static/images/2023-ui/icons/Close.svg';
import './_menuButton.less';

const MenuButton = ({ isOpen, handleBtnToggle }) => (
    <button
        className='menu-button'
        data-cy='menu-button'
        type='button'
        title='Navigation Menu'
        onClick={handleBtnToggle}>
        {isOpen ? <CloseIcon /> :  <MenuIcon />}
    </button>
);

MenuButton.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleBtnToggle: PropTypes.func.isRequired,
};

export default MenuButton;